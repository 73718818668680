<template>
    <div class="zoomable-wrapper">
        <div class="zoomable-wrapper__actions">
            <slot name="actions" />

            <el-button-group class="u-ml-auto">
                <el-button
                    :disabled="disabled"
                    @click="handleZoomOut"
                >
                    -
                </el-button>
                <el-button
                    :disabled="disabled"
                    @click="handleZoomReset"
                >
                    {{ `${formatNumber(zoomValue, 0)} %` }}
                </el-button>
                <el-button
                    :disabled="disabled"
                    @click="handleZoomIn"
                >
                    +
                </el-button>
            </el-button-group>
        </div>

        <div
            ref="inner"
            :class="['zoomable-wrapper__inner', innerClass]"
        >
            <div :style="innerStyle">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiZoomableWrapper'
});
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useFormat } from '@/composable/useFormat';

const { formatNumber } = useFormat();

interface Props {
    minZoom?: number,
    maxZoom?: number,
    stepZoom?: number,
    innerClass?: string,
    disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    minZoom: 0.1,
    maxZoom: 2,
    stepZoom: 0.1,
    innerClass: '',
    disabled: false
});

const inner = ref<HTMLElement | null>(null);
const currentScale = ref(1);
const zoomValue = computed(() => currentScale.value * 100);

const innerStyle = computed<Partial<CSSStyleDeclaration>>(() => ({
    transition: '0.3s',
    transformOrigin: '0 0',
    transform: `scale(${currentScale.value})`
}));

function handleZoomReset () {
    currentScale.value = 1;
}

function handleZoomOut () {
    const newZoom = parseFloat((currentScale.value - props.stepZoom).toFixed(2));
    if (newZoom >= props.minZoom) {
        currentScale.value = newZoom;
    }
}

function handleZoomIn () {
    const newZoom = parseFloat((currentScale.value + props.stepZoom).toFixed(2));
    if (newZoom <= props.maxZoom) {
        currentScale.value = newZoom;
    }
}
</script>

<style lang="scss">
.zoomable-wrapper {
    display: flex;
    flex-direction: column;

    &__actions {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    &__inner {
        overflow: auto;
    }
}
</style>
